import { createContext, useContext, useEffect, useState } from "react";

const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
  const defaultValues = {
    filter: "all",
    search: "",
    order: "DESC",
    account: "all",
    typeIndex: 0,
    orderIndex: 0,
    accountIndex: 0,
    page: 0,
    size: 20,
    totalCount: 0,
  };

  const [filters, setFiltersInternal] = useState(defaultValues);

  const setFilters = (value) => {
    if (typeof value === 'function') {
      setFiltersInternal((prevState) => value(prevState));
    } else {
      setFiltersInternal(value);
    }
  };

  const contextValue = {
    filters,
    setFilters,
    reset: () => setFilters(defaultValues),
    resetPage: () => setFilters({...filters, page: 0})
  };

  return (
    <FiltersContext.Provider value={contextValue}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FiltersContext);
  return context;
};
