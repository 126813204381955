import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useState } from "react";
import { useFilters } from "src/contexts/filters-context";

export const RecentsOrdering = () => {
  const options = [
    { value: "DESC", label: "Mais recentes" },
    { value: "ASC", label: "Mais antigas" },
  ];

  const { filters, setFilters } = useFilters();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index, order) => {
    setFilters((prevState) => ({ ...prevState, order, orderIndex: index, page: 0 }));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <List component="nav" aria-label="Device settings">
        <ListItem
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{ height: "30px", marginRight: "16px", cursor: "pointer" }}
        >
          <ListItemText
            primary={
              <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                {options[filters.orderIndex]?.label}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <UnfoldMoreIcon
        sx={{
          position: "absolute",
          top: "50%",
          right: "8px",
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
        onClick={handleClickListItem}
      />
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{ width: "100%" }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === filters.orderIndex}
            onClick={() => handleMenuItemClick(index, option.value)}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
